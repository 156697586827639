import {provide, ref, watch} from "vue";

const defaultModal = {
    'title': 'Demande de confirmation',
    'content': 'Êtes-vous sûr de vouloir effectuer cette action ?',
    'leftButton': 'Annuler',
    'rightButton': 'Valider'
}

export const modalConfirmTitle = ref<string>();
export const modalConfirmContent = ref<string>();
export const modalConfirmLeftButton = ref<string>();
export const modalConfirmRightButton = ref<string>();
export const modalConfirmShow = ref(false);
export const DsModalConfirmResponse = ref<boolean| null>(null);

let modalResolve: ((value: boolean) => void) | null = null;
const promise = ref()
export async function DsAlertConfirm(newTitle?: string, newContent?: string, newLeftButton?: string, newRightButton?: string) {
    modalConfirmTitle.value = newTitle ?? defaultModal.title
    modalConfirmContent.value = newContent ?? defaultModal.content
    modalConfirmLeftButton.value = newLeftButton ?? defaultModal.leftButton
    modalConfirmRightButton.value = newRightButton ?? defaultModal.rightButton

    modalConfirmShow.value = true;

    return new Promise<boolean>((resolve) => {
        modalResolve = resolve;
    });
}

export function modalConfirmValidate(response: boolean) {
    if (modalResolve) {
        modalResolve(response);
    }
    DsModalConfirmResponse.value = response
    modalConfirmShow.value = false
}
watch(modalConfirmShow, (result) => {
    if (result === false) {
        modalConfirmTitle.value = defaultModal.title
        modalConfirmContent.value = defaultModal.content
        modalConfirmLeftButton.value = defaultModal.leftButton
        modalConfirmRightButton.value = defaultModal.rightButton
        modalResolve = null;
    }
})
